/* CSS for 1700-tallet */
@import "../../../../../../lib/uio1/profile/css/util.less";

// UU

#hidnav a[href="#bottomnav"] {
  display: none;
}
#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
#main input[type="button"]:not(.red),
#main input[type="submit"]:not(.submit-comment-button):not(.red),
#main input[type="cancel"]:not(.red),
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white) {
  background-color: @color-primary;
  &:focus,
  &:hover {
    background-color: @color-primary--dark;
  }
}
.not-for-ansatte {
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
    .header-search-expand,
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
  #head-wrapper {
    background: @color-light url("../images/banner-wide.png") repeat-x left top;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png") no-repeat scroll
        100% 55%;
    }
    #head {
      .uio-app-name {
        display: none;
      }
      height: 159px;
      .head-menu {
        right: 110px;
        .language {
          display: none;
        }
        a {
          color: @color-dark;
          margin-top: -5px;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        img {
          position: absolute;
          top: 0px;
          left: -150px;
        }
        .logo,
        .header-text {
          font-family: georgia, garamond, serif;
          left: 140px;
          position: absolute;
          color: @color-dark;
          top: 50px;
        }
        .logo {
          a {
            color: @color-neutral--dark;
          }
          font-size: 70px;
          font-size: 7rem;
        }
        .header-text {
          font-size: 25px;
          font-size: 2.5rem;
          top: 110px;
        }
      }
    }
  }
}
#globalnav {
  background: url("../images/globalnav-bg.png") repeat-x scroll 0 0 transparent;
  ul li {
    a,
    &.vrtx-active-item a {
      color: @color-light;
      &:hover,
      &:focus {
        background: @color-neutral--light none repeat scroll 0 0;
        color: @color-dark;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-child {
    border-bottom: 1px @color-neutral--air solid;
    a {
      color: @color-link;
      background-color: @color-neutral--light;
      border-top: none !important;
      background: rgba(0, 0, 0, 0)
        url("/vrtx/decorating/resources/dist/images/li-arrow.png") no-repeat
        scroll 10px 23px;
    }
  }
  a {
    &.vrtx-marked {
      background-color: @color-primary !important;
      color: @color-light;
      border-top: none !important;
    }
    &:hover,
    &:focus {
      background-color: @color-neutral--light;
    }
  }
  span::before,
  a::before {
    content: " " !important;
  }
}

table th {
  font-weight: bold;
}

#vrtx-additional-content .vrtx-frontpage-box {
  border-bottom: 4px solid @color-lines;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-blue.svg") no-repeat 0 3px !important;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-blue.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-blue.svg");
  }
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background-color: @color-secondary;
    *,
    a {
      color: @color-neutral--dark;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0) url("../uio1/images/dropdown-black.png")
        no-repeat scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      background: @color-light url("../images/banner.png") repeat-x left bottom;
      height: 152px;
      #head {
        height: 104px;
        #header {
          img {
            position: absolute;
            top: 0px;
            left: -97px;
            padding: 0 10px 10px;
            max-height: 114px;
          }
          .logo,
          .header-text {
            left: 120px;
            top: 20px;
          }
          .logo {
            a {
              color: @color-neutral--dark;
            }
            font-size: 30px;
            font-size: 3rem;
          }
          .header-text {
            font-size: 16px;
            font-size: 1.6rem;
            line-height: 2rem;
            line-height: 20px;
            top: 57px;
          }
        }
      }
    }
    .vrtx-box-content {
      padding: 0 20px;
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
  }
  .menu-search {
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
  #vrtx-additional-content .vrtx-frontpage-box {
    border-bottom: none;
  }
}

/* Mixin */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

@color-primary: #3a2710;
@color-primary--dark: darken(spin(@color-primary, -5), 10%);
@color-secondary: #dcd2bc;
@color-link: #2771bb;

// Neutral colors
@color-neutral--dark: #2b2b2b;
@color-neutral: #949494;
@color-neutral--light: #eaedee;
@color-neutral--air: #e5e5e5;
@color-lines: #eaeaea;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #2b2b2b;
@footer-color: @color-neutral--light;
