/*
 * Commonly used mixins
 */

.borderRadius (@radius) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

.boxSizing (@border) {
    -webkit-box-sizing: @border;
    -moz-box-sizing: @border;
    box-sizing: @border;
}

.gradient (@from , @to) {
    background: -webkit-linear-gradient(top, @from, @to);
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(@from), to(@to));
    background: -moz-linear-gradient(top, @from, @to);
    background: -ms-linear-gradient(top, @from, @to);
    background: -o-linear-gradient(top, @from, @to);
}

.boxShadow (@shadow) {
    -webkit-box-shadow: @shadow;
    -moz-box-shadow: @shadow;
    box-shadow: @shadow;
}

.userSelect (@select) {
    -moz-user-select: @select;
    -webkit-user-select: @select;
    -ms-user-select: @select;
    user-select: @select;
}

.transition (@seconds, @prop: all, @easing: ease-in-out) {
    -webkit-transition: @prop @seconds @easing;
    -moz-transition: @prop @seconds @easing;
    -o-transition: @prop @seconds @easing;
    transition: @prop @seconds @easing;
}

.translate (@x, @y) {
    .transform(translate(@x, @y));
}

.translate3d (@x, @y, @z) {
    .transform(translate3d(@x, @y, @z));
}

.transform (@props) {
    -webkit-transform: @props;
    -moz-transform: @props;
    -ms-transform: @props;
    -o-transform: @props;
    transform: @props;
}

.backfaceVisibilityHidden () {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}

.backgroundClip () {
    -moz-background-clip: border;
    -webkit-background-clip: border;
    background-clip: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    -moz-background-clip: content;
    -webkit-background-clip: content;
    background-clip: content-box;
}

.animation(@name, @duration, @easing: ease-in-out, @delayedStart: 0s, @iterationCount: 1, @direction: normal) {
    -webkit-animation: @arguments;
    -moz-animation: @arguments;
    animation: @arguments;
}

.keyframes(@name, @args) {
    @-moz-keyframes @name { @args(); }
    @-webkit-keyframes @name { @args(); }
    @keyframes @name { @args(); }
}

.centerImageInContainer(@left: 50%, @top: 50%) {
    position: relative;

    img {
        position: absolute;
        left: @left;
        top: @top;
        .translate(-50%,-50%);
    }
}

.circle (@radius) {
    width: @radius;
    height: @radius;
    .borderRadius(@radius);
}

.placeholderColor (@color, @focusColor) {
    &::-webkit-input-placeholder {
        color: @color;
    }
    &:-moz-placeholder {
        color: @color;
    }
    &::-moz-placeholder {
        color: @color;
    }
    &:-ms-input-placeholder {
        color: @color;
    }

    &:focus {
        &::-webkit-input-placeholder {
            color: @focusColor;
        }
        &:-moz-placeholder {
            color: @focusColor;
        }
        &::-moz-placeholder {
            color: @focusColor;
        }
        &:-ms-input-placeholder {
            color: @focusColor;
        }
    }
}

.font-size(@sizeValue) {
    @remValue:(@sizeValue / 10);
    @pxValue: @sizeValue;
    font-size: ~"@{pxValue}px";
    font-size: ~"@{remValue}rem";
}

.line-height(@sizeValue) {
    @remValue: (@sizeValue / 10);
    @pxValue: @sizeValue;
    line-height: ~"@{pxValue}px";
    line-height: ~"@{remValue}rem";
}

.font-size-resp(@sizeValue) {
    @remValue: ((@sizeValue * 0.82) / 10);
    @pxValue: @sizeValue;
    font-size: ~"@{pxValue}px";
    font-size: ~"@{remValue}rem";
}

.line-height-resp(@sizeValue) {
    @remValue: ((@sizeValue * 0.82) / 10);
    @pxValue: @sizeValue;
    line-height: ~"@{pxValue}px";
    line-height: ~"@{remValue}rem";
}

.clearfix () {
    content: "";
    display: table;
    clear: both;
}

.newLine() {
    content: "\a ";
    white-space: pre-wrap;
}

.flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.flex(@values) {
    -webkit-box-flex: @values;
    -moz-box-flex:  @values;
    -webkit-flex:  @values;
    -ms-flex:  @values;
    flex:  @values;
}

.flex-order(@val) {
    -webkit-box-ordinal-group: @val;
    -moz-box-ordinal-group: @val;
    -ms-flex-order: @val;
    -webkit-order: @val;
    order: @val;
}
